const ApiUrl = {
  OAuth: {
    GOOGLEDRIVE: `${AUTH_URL}/auth/googledrive`,
    OUTREACH: `${API_URL}/outreach/oauth`,
    SALESFORCE: `${AUTH_URL}/auth/salesforce`,
    SALESLOFT: `${API_URL}/salesloft/oauth`,
  },
  Outreach: {
    SEQUENCES: `${API_URL}/outreach/sequences`,
    SETTINGS: `${API_URL}/outreach/settings`,
    USER: `${API_URL}/outreach`,
  },
  Salesloft: {
    USER: `${API_URL}/salesloft`,
  },
  USER: `${API_URL}/user`,
};

const Model = {
  outreachSequences: null,
  outreachSequencesLoaded: false,
  outreachSettings: null,
  outreachSettingsLoaded: false,
  outreachUser: null,
  outreachUserLoaded: false,
  salesloftUser: null,
  salesloftUserLoaded: false,
  user: null,
  userLoaded: false,
};

const ProspectAPI = {
  getOutreachSequences: async function () {
    try {
      const response = await $.get(ApiUrl.Outreach.SEQUENCES);

      return (response || {}).sequences || [];
    } catch (e) {
      console.error('Failed to fetch Outreach sequences');
      return null;
    }
  },

  getOutreachSettings: async function () {
    try {
      return await $.get(ApiUrl.Outreach.SETTINGS);
    } catch (e) {
      console.error('Failed to fetch Outreach settings');
      return null;
    }
  },

  getOutreachUser: async function () {
    try {
      return await $.get(ApiUrl.Outreach.USER);
    } catch (e) {
      console.error('Failed to fetch Outreach user');
      return null;
    }
  },

  getSalesloftUser: async function () {
    try {
      return await $.get(ApiUrl.Salesloft.USER);
    } catch (e) {
      console.error('Failed to fetch Salesloft user');
      return null;
    }
  },

  getUser: async function () {
    try {
      return await $.get(ApiUrl.USER);
    } catch (e) {
      console.error('Failed to fetch Prospect user');
      return null;
    }
  },

  putOutreachSettings: async function ({ sequences, syncEnabled }) {
    if (!Array.isArray(sequences) || syncEnabled === undefined) {
      throw new Error(
        `Invalid outreach settings - sequences: ${sequences},  syncEnabled: ${syncEnabled}`,
      );
    }

    return await $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({
        sequences,
        syncEnabled,
      }),
      type: 'PUT',
      url: ApiUrl.Outreach.SETTINGS,
    });
  },
};
